(function()
{
 	var app = angular.module('search', []);

 	app.run(["$rootScope", function($rootScope)
 	{
 		jQuery(".search-button").click(function(e)
		{
			e.preventDefault();
			var location = getCookie("location_id");
			if(location!=0 && location!=null && location!=''){
				jQuery("#search-dialog").appendTo("#overlay").removeClass("hidden");
				jQuery("#overlay").css(
				{
					left: "auto",
					right: 0
				}).animate(
				{
					height: "toggle",
					width: "toggle"
				}, 300, function()
				{
					jQuery(this).find("input").eq(0).focus();
				});
			}else{
				jQuery("#search-no-location-dialog").appendTo("#overlay").removeClass("hidden");
				jQuery("#overlay").css(
				{
					left: "auto",
					right: 0
				}).animate(
				{
					height: "toggle",
					width: "toggle"
				}, 300, function()
				{

				});
			}
		});

 		$rootScope.submitSearch = function()
 		{
 			$rootScope.closeSearch(function()
			{
				jQuery("#search-form").submit();
			});
 		};

 		$rootScope.closeSearch = function(callback)
 		{
 			jQuery("#overlay:visible").animate(
			{
				height: "toggle",
				width: "toggle"
			}, 300, function()
			{
				jQuery("#search-dialog").appendTo("body").addClass("hidden");

				if(typeof callback == "function")
				{
					callback();
				}
			});
 		};

		$rootScope.closeLocationDialog = function(callback)
 		{
 			jQuery("#overlay:visible").animate(
			{
				height: "toggle",
				width: "toggle"
			}, 300, function()
			{
				jQuery("#search-no-location-dialog").appendTo("body").addClass("hidden");

				if(typeof callback == "function")
				{
					callback();
				}
			});
 		};

		// jQuery(".close-search-dialog").click(function(e)
		// {
		// 	e.preventDefault();
		// 	jQuery( "#search-dialog" ).dialog("close");
		// });
	}]);
})();
